import Constants from '../../config.local.js'

var TiersMixin = {
	data() {
		return {

		}
	},
	methods: {
		loadSyndic: async function(syndic_id) {
			const url = this.constructRoute(Constants.SYNDIC_URL, { syndic_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_get_api("SyndicMixin::loadSyndic", url)
				.catch(error => {
					console.error("SyndicMixin::loadSyndic => ERROR", error)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

       loadSyndicBySeason: async function(syndic_id, season_id) {
            const url = this.constructRoute(Constants.SYNDIC_SEASON_URL, { syndic_id, season_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("SyndicMixin::loadSyndicBySeason", url)
			.catch(error => {
				console.error("SyndicMixin::loadSyndicBySeason => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        loadHorseSyndicBySeason: async function(horse_id, season_id, date = null) {
            let url = this.constructRoute(Constants.HORSE_SEASON_SYNDIC_URL, { horse_id, season_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

            if(date) {
                url += "&date=" + date.toDateInputValue()
            }

			const result = await this.$request.request_get_api("SyndicMixin::loadHorseSyndicBySeason", url)
			.catch(error => {
				console.error("SyndicMixin::loadHorseSyndicBySeason => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

		loadHorsePreviousSyndic: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_SYNDIC_PREVIOUS_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("SyndicMixin::loadHorsePreviousSyndic", url)
			.catch(error => {
				console.error("SyndicMixin::loadHorsePreviousSyndic => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        loadHorseSyndicDetails: async function(horse_id, syndic_id, season_id) {
            const url = this.constructRoute(Constants.HORSE_SYNDIC_DETAILS_URL, { horse_id, syndic_id, season_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("SyndicMixin::loadHorseSyndicDetails", url)
			.catch(error => {
				console.error("SyndicMixin::loadHorseSyndicDetails => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        editHorseSyndic: async function(syndic_id, params) {
            const url = this.constructRoute(Constants.SYNDIC_URL, { syndic_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
            	label: params.label,
                season: params.seasons,
                tier_syndicat: params.tier_id || 0,
                syndic_type: params.type,
                saillies_etalonnier: params.tier_saillies,
                saillies_part: params.part_saillies,
            }

			return this.$request.request_post_api("SyndicMixin::editHorseSyndic", url, params_post, false)
        },

        registerHorseSyndic: async function(horse_id, params) {
            const url = this.constructRoute(Constants.HORSE_SYNDIC_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
            	label: params.label,
                season: params.seasons,
                tier_syndicat: params.tier_id || 0,
                syndic_type: params.type,
                saillies_etalonnier: params.tier_saillies,
                saillies_part: params.part_saillies,
            }

			return this.$request.request_post_api("SyndicMixin::registerHorseSyndic", url, params_post, false)
        },

		cloneHorseSyndic: async function(horse_id, source_season, target_season) {
            const url = this.constructRoute(Constants.HORSE_SYNDIC_CLONE_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
                source_season,
                target_season
            }

			return this.$request.request_post_api("SyndicMixin::cloneHorseSyndic", url, params_post, false)
        },

        registerHorseCarriersParts: async function(horse_id, syndic_id, season_id, params) {
            const url = this.constructRoute(Constants.HORSE_CARRIERS_URL, { horse_id, syndic_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
            	season_id: season_id,
                tiers: params.tiers,
                parts: params.parts,
                invoice_type: params.invoice_type,
                saillies_perso: params.saillies_perso,
                saillies_pool: params.saillies_pool
            }

			return this.$request.request_post_api("SyndicMixin::registerHorseCarriersParts", url, params_post, false)
        },

        editHorseSyndicParts: async function(syndic_id, syndic_part_id, params) {
            const url = this.constructRoute(Constants.SYNDIC_EDIT_PART, { syndic_id, syndic_part_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
                tiers: params.tiers,
                invoice_type: params.invoice_type,
			    is_saillie_perso: params.is_saillie_perso,
			    is_saillie_pool: params.is_saillie_pool
            }

			return this.$request.request_post_api("SyndicMixin::editHorseSyndicParts", url, params_post, false)
        },

        editHorseSyndicMultiParts: async function(syndic_id, syndic_part_ids, params) {
            const url = this.constructRoute(Constants.SYNDIC_EDIT_MULTI_PART, { syndic_id: syndic_id, syndic_part_ids: syndic_part_ids.join() }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
                invoice_type: params.invoice_type,
                is_saillie_perso: params.is_saillie_perso,
                is_saillie_pool: params.is_saillie_pool
            }

            return this.$request.request_post_api("SyndicMixin::editHorseSyndicParts", url, params_post, false)
        },

        saveHorseCessionSyndicPart: async function(syndic_id, syndic_part_id, params) {
            const url = this.constructRoute(Constants.SYNDIC_ASSIGN_PART, { syndic_id, syndic_part_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const params_post = {
                tiers: params.tiers,
                cession_date: params.cession_date
            }

			return this.$request.request_post_api("SyndicMixin::saveHorseCessionSyndicPart", url, params_post, false)
        },

		loadSyndicTypes: async function() {
			const url = Constants.SYNDIC_TYPES_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("SyndicMixin::loadSyndicTypes", url)
			.catch(error => {
				console.error("SyndicMixin::loadSyndicTypes => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        loadSyndicInvoiceTypes: async function() {
			const url = Constants.SYNDIC_INVOICE_TYPES_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("SyndicMixin::loadSyndicInvoiceTypes", url)
			.catch(error => {
				console.error("SyndicMixin::loadSyndicInvoiceTypes => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        loadHistoriqueSyndicByStallion: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_SYNDIC_HISTORY_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("SyndicMixin::loadHistoriqueSyndicByStallion", url)
            .catch(error => {
                console.error("SyndicMixin::loadHistoriqueSyndicByStallion => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

		loadHistoriqueSyndicPart: async function(syndicpart_id) {
            const url = this.constructRoute(Constants.SYNDIC_PART_HISTORY_URL, { syndicpart_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("SyndicMixin::loadHistoriqueSyndicByStallion", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("SyndicMixin::loadHistoriqueSyndicByStallion => ERROR", e)
					return null
				})
        },

        loadHorseAllSyndic: async function(horse_id) {
        	const url = this.constructRoute(Constants.HORSE_SYNDIC_URL, { horse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("SyndicMixin::loadHorseSyndic", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("SyndicMixin::loadHorseSyndic => ERROR", e)
					return null
				})
        },

        loadSyndicByTier: async function(tiers_id, season_id) {
            const url = this.constructRoute(Constants.TIERS_SYNDIC_DETAILS_URL, { tiers_id, season_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            return this.$request.request_get_api("SyndicMixin::loadHorseSyndic", url)
                .then(res => res.retour)
                .catch(e => {
                    console.error("SyndicMixin::loadHorseSyndic => ERROR", e)
                    return null
                })
        },

        editDateCessionPart: async function(syndicpart_id, date_cession) {
            const url = this.constructRoute(Constants.SYNDIC_EDIT_DATE_URL, { syndicpart_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("SyndicMixin::editDateCessionPart", url, {date_cession}, false)
        }
    }
}

export default TiersMixin