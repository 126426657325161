<template>
	<div class="box">
		<SeasonInput v-model="seasonSelected" />

		<CustomTable 
			id_table="tiers_syndic_details"
			primaryKey="saillie_id"
			:items="tiers_syndic_details"
			:busy="loading"
			:externSlotColumns="extern_slot_columns"
            :hrefsRoutes="config_table_hrefs"
		>
			<template v-slot:custom-slot-cell(saillie_number)="{ data }">
				#{{ data.saillie_number }}
			</template>
			<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_tiers_formatted_table)`]="{ data }">
				<span v-for="tierpart in data.saillie_part.syndicpart_tiers" :key="'tierpart_'+tierpart.tierpart_id">
					<router-link :to="{ name: 'tiersTableauBord', params: { tiers_id: tierpart.tierpart_tier.tiers_id }}"> {{ tierpart.tierpart_tier.tiers_rs }} ({{ tierpart.tierpart_percentage }}%)</router-link><br/>
				</span>
			</template>
			<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_is_perso)`]="{ data }">
				<font-awesome-icon v-show="data.saillie_part.syndicpart_is_perso" :icon="['far', 'check']" />
			</template>
			<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_is_pool)`]="{ data }">
				<font-awesome-icon v-show="data.saillie_part.syndicpart_is_pool" :icon="['far', 'check']" />
			</template>
			<template v-slot:[`custom-slot-cell(saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)`]="{ data }">
				{{ $t(data.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad) }}
			</template>
			
		</CustomTable>
	</div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Syndic from "@/mixins/Syndic.js"

	export default {
		name: 'TiersSyndication',
		mixins: [Navigation, Syndic],
        props: {
            tiers: { type: Object, default: null }
        },
		data () {
			return {
				tiers_syndic_details: null,
				extern_slot_columns: [
                    'saillie_number',
                    'saillie_part.syndicpart_is_perso',
                    'saillie_part.syndicpart_is_pool',
                    'saillie_part.syndicpart_invoice_type.invoicetype_labeltrad',
                    'saillie_part.syndicpart_tiers_formatted_table',
				],
				config_table_hrefs: {
                    'contract.contract_num': {
                        routeUniqueName: 'ContractForm',
                        routeName: 'ContractFormv2',
                        params: {
                            contract_id: 'contract.contract_id'
                        }
                    },
                    'horse.horse_nom': {
                        routeUniqueName: 'HorseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
                    },
                },
				seasonSelected: null,
				loading: false
			}
		},

		methods: {
			async loadSaillie() {
				if(!this.seasonSelected.id) {
                    return false
                }
                this.loading = true
				this.tiers_syndic_details = await this.loadSyndicByTier(this.tiers.tiers_id, this.seasonSelected.id)
                this.loading = false
			}
		}, 

		watch: {
            seasonSelected() {
                this.loadSaillie()
            },
        },

		components: {
            CustomTable: () => import("GroomyRoot/components/Table/CustomTable"),
            SeasonInput : () => import('@/components/Inputs/SeasonInput')
		}
	}

</script>