var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('SeasonInput',{model:{value:(_vm.seasonSelected),callback:function ($$v) {_vm.seasonSelected=$$v},expression:"seasonSelected"}}),_c('CustomTable',{attrs:{"id_table":"tiers_syndic_details","primaryKey":"saillie_id","items":_vm.tiers_syndic_details,"busy":_vm.loading,"externSlotColumns":_vm.extern_slot_columns,"hrefsRoutes":_vm.config_table_hrefs},scopedSlots:_vm._u([{key:"custom-slot-cell(saillie_number)",fn:function(ref){
var data = ref.data;
return [_vm._v(" #"+_vm._s(data.saillie_number)+" ")]}},{key:"custom-slot-cell(saillie_part.syndicpart_tiers_formatted_table)",fn:function(ref){
var data = ref.data;
return _vm._l((data.saillie_part.syndicpart_tiers),function(tierpart){return _c('span',{key:'tierpart_'+tierpart.tierpart_id},[_c('router-link',{attrs:{"to":{ name: 'tiersTableauBord', params: { tiers_id: tierpart.tierpart_tier.tiers_id }}}},[_vm._v(" "+_vm._s(tierpart.tierpart_tier.tiers_rs)+" ("+_vm._s(tierpart.tierpart_percentage)+"%)")]),_c('br')],1)})}},{key:"custom-slot-cell(saillie_part.syndicpart_is_perso)",fn:function(ref){
var data = ref.data;
return [_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(data.saillie_part.syndicpart_is_perso),expression:"data.saillie_part.syndicpart_is_perso"}],attrs:{"icon":['far', 'check']}})]}},{key:"custom-slot-cell(saillie_part.syndicpart_is_pool)",fn:function(ref){
var data = ref.data;
return [_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(data.saillie_part.syndicpart_is_pool),expression:"data.saillie_part.syndicpart_is_pool"}],attrs:{"icon":['far', 'check']}})]}},{key:"custom-slot-cell(saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(data.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }